// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bK_k3";
export var caseStudyBackground__lineColor = "bK_k0";
export var caseStudyHead__imageWrapper = "bK_kX";
export var caseStudyProjectsSection = "bK_k4";
export var caseStudyQuote__bgQuote = "bK_mm";
export var caseStudyQuote__bgRing = "bK_kZ";
export var caseStudyVideo__ring = "bK_k2";
export var caseStudy__bgDark = "bK_kV";
export var caseStudy__bgLight = "bK_kT";
export var caseStudy__bgLightGray = "bK_ml";